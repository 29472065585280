<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm mới thuộc tính'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="col-md-12 mb-4">
                <div class="row">
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <label>Nhóm thuộc tính:</label>
                    </b-row>
                    <b-row class="my-1">
                      <b-form-select
                        size="sm"
                        id="input-1"
                        v-model="selectedGroup"
                        :options="listGroup"
                        required
                        value-field="id"
                        text-field="name"
                        class="mb-3"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn nhóm thuộc tính</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label>Thuộc tính cha:</label>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col>
                        <b-form-select
                          size="sm"
                          id="input-2"
                          v-model="selectedParent"
                          :options="listProperty"
                          required
                          value-field="id"
                          text-field="name"
                          class="mb-3"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >Chọn thuộc tính cha</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <label>Tên thuộc tính:</label>
                    </b-row>
                    <b-row class="my-1">
                      <b-input
                        type="text"
                        id="inline-form-input-name"
                        placeholder="Nhập tên thuộc tính"
                        append-icon="search"
                        single-line
                        hide-details
                        class="col-md-12 form-control-sm"
                        v-model="$v.form.propertyName.$model"
                        :state="validateState('propertyName')"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        Nhập tên thuộc tính với ít nhất 3 ký tự!
                      </b-form-invalid-feedback>
                    </b-row>
                  </div>
                  <div class="col-md-3">
                    <b-row class="my-1">
                      <b-col>
                        <label>Kiểu dữ liệu:</label>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col>
                        <b-form-select
                          size="sm"
                          id="input-3"
                          v-model="selectedDataType"
                          :options="listDataType"
                          required
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >Chọn kiểu dữ liệu</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <p>
                <strong>Giá trị</strong>
              </p>
              <div class="mb-4">
                <button
                  v-bind:style="btnCreate"
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="addRow"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                  mới
                </button>
              </div>
              <div class="col-md-8" style="padding-left: 0">
                <table
                  v-show="listProductPropertyValue.length >= 1"
                  class="table table-bordered table-vertical-center table-hover"
                >
                  <thead>
                    <tr>
                      <th style="textalign: center; color: rgb(24, 28, 50)">
                        STT
                      </th>
                      <th class="name">Tên</th>
                      <th class="value">Giá trị</th>
                      <th class="code">Mã</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody
                    v-for="(item, index) in listProductPropertyValue"
                    :key="index"
                  >
                    <ProductPropertyValue
                      v-bind:item="item"
                      v-bind:index="index"
                      v-on:updateItem="updateValue"
                      v-bind:listFeature="listFeature"
                      v-on:deleteItem="deleteValue"
                      v-on:addItem="addNewValue"
                      v-on:cancelAdd="cancelAdd"
                    />
                  </tbody>
                </table>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createProperty"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <router-link to="/properties" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
input::-webkit-calendar-picker-indicator {
  display: none;
}
table {
  width: 100%;
  border: 1px solid #000;
}
th.id {
  width: 10%;
}
th.code {
  width: 20%;
}
th.name,
th.value {
  width: 30%; /* Not necessary, since only 70% width remains */
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import ProductPropertyValue from '../../components/ProductPropertyValue.vue';
import localData from '../../../utils/saveDataToLocal';

export default {
  mixins: [validationMixin],
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
      },
      valid: true,
      form: {
        propertyName: '',
      },
      selectedGroup: null,
      listGroup: [],
      selectedDataType: null,
      listDataType: [
        { id: 1, name: 'Color' },
        { id: 2, name: 'Size' },
        { id: 3, name: 'Storage' },
        { id: 4, name: 'Status' },
      ],
      selectedParent: null,
      listProperty: [],
      selectedId: null,
      itemChoice: null,
      listFeature: [],
      listProductPropertyValue: [],
      count: 0,
      isNew: true,
    };
  },
  validations: {
    form: {
      propertyName: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    KTCodePreview,
    ProductPropertyValue,
  },
  created() {
    this.getListPropertyGroup();
    this.getListProperty();
    this.getListFeature();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thuộc tính', route: '/properties' },
      { title: 'Danh sách thuộc tính', route: '/properties' },
      { title: 'Thêm mới thuộc tính' },
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    createProperty: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (!this.isValidData(this.$v.form.propertyName.$model)) {
        alert('Tên thuộc tính không được chứa ký tự đặc biệt!');
        return;
      }
      if (this.selectedDataType === null) {
        alert('Vui lòng chọn kiểu dữ liệu!');
        return;
      }
      if (this.listProductPropertyValue.length === 0) {
        alert('Vui lòng nhập giá trị cho thuộc tính!');
        return;
      }
      const name = this.$v.form.propertyName.$model;
      const productCategoryId = null;
      const productPropertyGroupId = this.selectedGroup;
      const productPropertyParentId = this.selectedParent;
      const type = this.selectedDataType;
      let data = {
        name: name,
        productCategoryId: productCategoryId,
        productPropertyGroupId: productPropertyGroupId,
        productPropertyParentId: productPropertyParentId,
        type: type,
        listValue: this.listProductPropertyValue,
      };
      ApiService.setHeader();
      ApiService.post('property/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/properties',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getListPropertyGroup: async function () {
      this.listGroup = [];
      let param = {
        page: 1,
        limit: 10,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('propertyGroup', paramQuery).then(({ data }) => {
        data.data.list_property_group.forEach((element) => {
          let item = {
            id: element.id,
            name: element.name,
          };
          this.listGroup.push(item);
        });
      });
    },
    getListProperty: async function () {
      this.listProperty = [];
      //  init params
      let param = {
        page: 1,
        limit: 10,
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('property', paramQuery).then(({ data }) => {
        data.data.list_property.forEach((item) => {
          let property = {
            id: item.id,
            name: item.name,
          };
          this.listProperty.push(property);
        });
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    getListFeature() {
      this.listFeature = localData.getData('listFeature');
    },
    addRow() {
      this.count = 1;
      if (this.listProductPropertyValue.length > 0) {
        this.count = this.listProductPropertyValue[
          this.listProductPropertyValue.length - 1
        ].count++;
      }
      let data = {
        count: this.count,
        id: this.count,
        name: '',
        code: '',
        value: '',
      };
      // if (this.listProductPropertyValue[0].id != "") {
      this.listProductPropertyValue.unshift(data);
      // }
    },
    cancelAdd() {
      this.listProductPropertyValue.shift();
    },
    addNewValue(item) {
      this.listProductPropertyValue[0].id = item.id;
      this.listProductPropertyValue[0].name = item.name;
      this.listProductPropertyValue[0].value = item.value;
      this.listProductPropertyValue[0].code = item.code;
      this.listProductPropertyValue.sort(this.compare);
    },
    updateValue(item) {
      this.listProductPropertyValue.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductPropertyValue[index].id = item.id;
          this.listProductPropertyValue[index].name = item.name;
          this.listProductPropertyValue[index].value = item.value;
          this.listProductPropertyValue[index].code = item.code;
        }
      });
      this.listProductPropertyValue.sort(this.compare);
    },
    deleteValue(id) {
      for (var i = 0; i < this.listProductPropertyValue.length; i++) {
        if (this.listProductPropertyValue[i].id === id) {
          this.listProductPropertyValue.splice(i, 1);
        }
      }
      this.listProductPropertyValue.sort(this.compare);
    },
    compare(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    isValidData(data) {
      var format = /[`!@#$%^&*()+\-={};':"|,.<>?~]/;
      return !format.test(data);
    },
  },
};
</script>

